import React from 'react'
import Layout from '../../staticcomponents/layout'
import './globalservice.scss'
import Header from './header'
import GlobalTab from './globaltab'
import Spotlights from './spotlights'
import Container from '@material-ui/core/Container';


export default function GlobalServices() {
    return (
        <>
            <Layout>
                <div className='globalservice-main'>
                    <div className='bg-donuts-left'>
                        <div className='bg-donuts-right'>                            
                                <Header />
                                <GlobalTab />
                                <Spotlights />
                        </div>
                    </div>

                </div>
            </Layout>
        </>
    )
}