import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import './header.scss';
import global_service_header_img from '../../../assets/global-services-header.png';
import global_service_teamwork_img from '../../../assets/global-service-teamwork.png';
import global_service_quote_img from '../../../assets/blue quote.png';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Container from '@material-ui/core/Container';
import { Link } from "gatsby";

export default function Header() {
  // const [age, setAge] = React.useState('');

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  // dropdown open     
  const [dropdownEl, setdropdownEl] = React.useState(null);
  const dropdownOpen = Boolean(dropdownEl);
  const dropdownClick = (event) => {
    setdropdownEl(event.currentTarget);
  };
  const dropdownClose = () => {
    setdropdownEl(null);
  };
  return (
    <>
      {/* <grid for circle image > */}

      <Grid className='global-service-header-cls'>
      <Container maxWidth="xl" className='globalcontentheader-container'>
        <Grid container className='header-container'>
          <Grid item xs={6} sm={6} md={5} lg={5} xl={5} className="header-left-text">
            <h1 className='title'>predecision services</h1>
            <p>compassionate support.</p>
            <p> exceptional technology. </p>
            <p>unbound service. </p>
            <p>‘that’s cartus experience’</p>
          </Grid>

          <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
            <div className='header-image'>
              <img src={global_service_header_img} alt="where mobility meets agility" width="100%" />
            </div>
          </Grid>

        </Grid>
      </Container>
      </Grid>

      {/* grid for left text */}
      <Container maxWidth="xl" className='globalcontentheader-container'>
      <Grid container item className='text-with-dropdown-div'>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={7}>
          <p>
            <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </div>
            <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
            </div>
          </p>
          <p>Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={5} className="dropdown-div">
          {/* <FormControl sx={{ m: 1, minWidth: 120, width: 327}}>
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          className="menu-items"
          endIcon={<KeyboardArrowDownIcon />}
        >
          <MenuItem value="">
            pre-decision services
          </MenuItem>
          <MenuItem value={1}>a</MenuItem>
          <MenuItem value={2}>b</MenuItem>
          <MenuItem value={3}>c</MenuItem>
        </Select>        
      </FormControl> */}
          <Button
            id="dropdown"
            aria-controls={dropdownOpen ? 'pre-decision services' : undefined}
            aria-haspopup="true"
            aria-expanded={dropdownOpen ? 'true' : undefined}
            onClick={dropdownClick}
            className="navbar-btns"
          >
            <span className="dropdown-txt-bold">pre-decision services</span>
            {dropdownOpen ?
              <ExpandLessIcon className="expand-icons-desk" />
              : <ExpandMoreIcon className="expand-icons-desk" />}
          </Button>
          <Menu
            id="dropdown-menu"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={dropdownEl}
            open={dropdownOpen}
            onClose={dropdownClose}
            MenuListProps={{
              'aria-labelledby': 'logins',
            }}
          >
            <MenuItem onClick={dropdownClose} className="menu-items">
              pre-decision services 1</MenuItem>
            <MenuItem onClick={dropdownClose} className="menu-items">
              pre-decision services 2</MenuItem>
            <MenuItem onClick={dropdownClose} className="menu-items">
              pre-decision services 3</MenuItem>
          </Menu>
        </Grid>
      </Grid>
      </Container>

      {/* grid for image with text */}
      <Container maxWidth="xl" className='globalcontentheader-container'>
      <Grid container item className='text-with-image-div'>
        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className='left-image'>
        <Grid className="globalcontent-intro-Xspace-image">
          <img src={global_service_teamwork_img} alt="team working on benefits builder" />
          <p>team working on benefits builder</p>
        </Grid>
        </Grid>
        <Grid item xs={11} sm={9} md={10} lg={5} xl={5} className="right-text-div">
          <Grid className="globalcontent-intro-Xspace">
          <img src={global_service_quote_img} alt="Quote icon" />
          <p>
            Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. scelerisque. Curabitur quam nulla, bibendum eu felis ac, porttitor blandit risused <Link to='#'>movepro 360</Link> magna.
          </p>
          <p className="author-name">LAURA BERRINGTON</p>
          <p className="designation">Technology Officer</p>
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </>
  )
}


